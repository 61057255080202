import type { EInstances } from '@/static/enums'

export const STORAGE_NAMES = {
  AUTH_TOKEN: 'var_auth_token',
  USER_LANG: 'var_user_lang',
  CLIENT_ANKET: 'var_client_anket',
  IS_NAVIGATION_BLOCKED: 'var_is_navigation_blocked',
  CURRENT_INSTANCE: 'var_current_instance',

  __DEBUG_CLIENT_ANKET: 'var_debug_client_anket',
} as const

export const BUFFER_STORE_NAMES = {
  CLIENT_ANKET: 'var_client_anket',
}

export const SUPPORTED_LOCALES = {
  EN: 'en',
  RU: 'ru',
  ES: 'es',
} as const

export const OPERATION_STATUS = {
  SUCCESS: 'SUCCESS__',
  ERROR: 'ERROR__',
  HANDLED_ERROR: 'HANDLED_ERROR__',
  NOT_FOUND: 'NOT_FOUND__',
  UNHANDLED: 'UNHANDLED__',
  UNSUPPORTED: 'UNSUPPORTED__',
  UNEXPECTED: 'UNEXPECTED__',
  INVALID: 'INVALID__',
  BLOCKED: 'BLOCKED__',
  EMPTY: 'EMPTY__',
} as const

export const CONSOLE_COLORS = {
  LOG_ACCENT_COLOR: '#5f5fc4',
  LOG_ERROR_COLOR: '#DB5461',
  LOG_SAFETY_COLOR: '#10b348',
} as const

export const BACK_DEV_URL = 'https://dev-crm.doctorpeso.co/'

export const CURRENT_INSTANCE = import.meta.env.VITE_CURRENT_INSTANCE as EInstances
