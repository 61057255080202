export enum EQueuesCommunicationStatuses {
  SUCCESSFULLY = 1,
  NOT_AVAILABLE,
  CALL_BACK,
  REFUSED_TO_COMMUNICATE,
  THE_OFFER_IS_NOT_INTERESTING,
  WRONG_DATA,
  APPLICATION_NOT_VALID,
  SHOWED_INTEREST_IN_THE_OFFER,
  GIVE_PROMISE,
  FRAUD,
  ANSWERING_MACHINE,
  OPEN_CASE,
  FAILED_CONVERSATION,
  SUCCESSFUL_AUTOMATIC_CALL,
  NO_ATTEMPT_TO_CALL
}

export enum EQueueSigns {
  MARKETING = 1,
  DAILY_CALLS,
  COLLECTION,
  ALERT,
  CALLING,
  OTHER,
}

export enum EQueueTypes {
  COMMUNICATION = 1,
  SEMI_AUTOMATIC,
  AUTOMATIC,
}

export enum EQueueLifeCycles {
  RENEWABLE = 1,
  NOT_UPDATABLE,
}

export enum EQueueStatuses {
  ACTIVATE = 1,
  SUSPEND,
  CLOSE,
  POSTPONED,
  IN_DEVELOPING,
  COMPLETED,
  IN_WORK
}

export enum EQueueClientStatuses {
  ACTIVE = 1,
  IN_PROGRESS,
  POSTPONED,
  COMPLETED,
  NEW,
  NOT_RELEVANT,
  NOT_READ,
  READ,
  IN_SPAM,
  CANCELED
}

export enum EQueueLoanStatuses {
  ACTIVE = 1,
  CANCEL
}

export enum ECommunicationTypes {
  INCOMING_CALL = 1,
  SMS,
  EMAIL,
  OUTGOING_CALL,
  WHATS_APP,
  WEB_CHAT,
  MESSAGE_BIRD,
  MOBILE_BANK,
  PUSH,
  TEMPLATE_SMS,
  CHAT_NEWSLETTER,
  OTHER_SERVICE,
  PUSH_NOTIFICATION,
  CHAT_SERVICE,
  GENERAL_SMS
}

export enum ETranslateTypes {
  WORK_PLACE = 0,
  WIDGET,
  ADMIN_PANEL,
  COMMON,
  REPORT_WP,
  KNOWLEDGE,
  CRM
}

export enum EQueueUpdateTypes {
  STANDART = 1,
  DAILY_MAILING
}

export enum EUsageZone {
  ZONE_CHATS = 1,
  ZONE_QUEUES,
  ZONE_WIDGET,
  ZONE_EXTERNAL_SERVICE,
}

export enum EQueueLifeCycleUpdateTypes {
  UPDATE_CYCLE_BY_MUNITES = 1,
  UPDATE_CYCLE_BY_DAYS,
  UPDATE_CYCLE_BY_DAYS_OF_WEEK
}

export enum ECommunicationStatuses {
  CREATED = 0,
  NOT_SENT,
  SENT,
  VALIDATION_ERROR,
  REQUEST_CREATED,
  READ,
  INCORRECT_CLIENT_DATA,
  OPENED,
  EMPTY_CLIENT_DATA,
  NOT_DELIVERED,
  DELIVERED,
  CANCELED,
  UNSUBSCRIBED
}

export enum EProjectIdentifier {
  CRM = 1,
  WIDGET,
  REPOTS
}

export enum ELanguages {
  ENGLISH = 'en',
  RUSSIAN = 'ru',
  SPANISH = 'es'
}

export enum ESubjectOfCommunicationStatuses {
  USER = 1,
  AUTO
}

export enum EServiceResult {
  OK = 'OK',
  FAILED = 'Failed',
  SENT = 'Sent',
  ERROR = 'Error'
}

export enum EDayOfWeek {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export enum ELanguages2 {
  RUSSIAN = 1,
  ENGLISH,
  HINDI,
  SPANISH,
  UZBEK,
}

export enum ECommunicationStatusFilter {
  COMMUNICATION = 1,
  PROMISE
}

export enum EFilteringMethodForStatus {
  INCLUDE = 1,
  EXCLUDE,
  LAST
}

export enum EQueueCommunicationStatusParameterNames {
  CALL_BACK_DATE_TIME = 1,
  COMMENT,
  PROMISE_DATE,
  PROMISE_AMOUNT
}

export enum EClientPhoneType {
  GENERAL_PHONE = 1,
  WORK_PHONE,
  GUARANTOR_PHONE,
  ADDITIONAL_PHONE1,
  ADDITIONAL_PHONE2,
  ADDITIONAL_PHONE3,
}

export enum EGroupSetting {
  GENERAL = 1,
  COMPANY,
  SCRM,
  EMAIL_MASS_MAILING,
  W_A_MASS_MAILING,
  MESSAGE_BIRD_SERVICE,
  CHAT_SERVICE,
  KNOWLEDGE_BASE_SERVICE,
  SENDBOX,
  PUSH_SERVICE,
  UNSUBSCRIBE_SERVICE,
  U_R_L_SHORTENER,
  COMPANY_TAGS
}

export enum ETagTypes {
  PROFILE = 1,
  EXTERNAL_SERVICE,
  COMPANY
}
