type UOperationStatuses = typeof OPERATION_STATUS[keyof typeof OPERATION_STATUS];

export type IStoreResultContainer<T, ResponseStatus extends number | null, Status extends UOperationStatuses = UOperationStatuses > = {
  data: T extends undefined ? undefined : T;
  operationStatus: Status;
  responseStatus: ResponseStatus | null;
};

type ICreateResultArgs<T, ResponseStatus extends number | null, Status extends UOperationStatuses > = {
  data?: T;
  operationStatus: Status;
  responseStatus?: ResponseStatus | null;
};

export const createResult = <Status extends UOperationStatuses, ResponseStatus extends number | null = null, T = undefined>(
  { operationStatus, data, responseStatus = null }: ICreateResultArgs<T, ResponseStatus, Status>,
): IStoreResultContainer<T, ResponseStatus, Status> => ( {
  data: data as T extends undefined ? undefined : T,
  operationStatus,
  responseStatus,
} )
