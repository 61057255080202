export const getDeepObjVal = ( instance, path ) => {
  const props = path.split( '.' )

  let result = instance
  for ( const prop of props ) {
    result = result[ prop ]

    if ( result === undefined || result === null ) {
      return null
    }
  }

  return result
}
