import { createI18n } from 'vue-i18n'
import { getDeepObjVal } from '@functions/getDeepObjVal'
import { universalWebWorker } from '@modules/universalWorker/index.ts'

import { getDictArray  } from '@static/backend_shared_dicts/index.ts'

const languages = getDictArray( 'Languages' )
const formLocaleMessages = ( helpers, groupedTranslates ) => {
  const messages = helpers.languages.reduce( ( acc, language ) => {
    const locale = language.id
    acc[ locale ] = {}
    return acc
  }, {} )

  for ( const groupedTranslate of groupedTranslates ) {
    const { keyword, locales } = groupedTranslate
    const [ dictName, translateName ] = keyword.split( '.' )

    Object.keys( locales ).forEach( ( locale ) => {
      if ( !messages[ locale ][ dictName ] ) {
        messages[ locale ][ dictName ] = {}
      }

      if ( locales[ locale ] !== null ) {
        messages[ locale ][ dictName ][ translateName ] = locales[ locale ][ 'translate' ]
      }
    } )
  }

  return messages
}

export class I18nUtils {
  static i18n = null

  static get locale() {
    return I18nUtils.i18n.global.locale
  }

  static setupI18n( options = { locale: 'en' } ) {
    options.missingWarn = import.meta.env.PROD
    options.fallbackWarn = import.meta.env.PROD

    const i18n = createI18n( options )

    window.$i18n = i18n.global

    I18nUtils.i18n = i18n
    return i18n
  }

  static setI18nLocale ( locale ) {
    if ( I18nUtils.i18n.mode === 'legacy' ) {
      I18nUtils.i18n.global.locale = locale
    } else {
      I18nUtils.i18n.global.locale.value = locale
    }

    document.querySelector( 'html' ).setAttribute( 'lang', locale )
  }

  static getTranslatesForMessage( message ) {
    const messages = I18nUtils.i18n.global.messages.value
    const translates = {}

    for ( const { id } of languages ) {
      translates[ id ] = getDeepObjVal( messages, `${ id }.${ message }` ) ?? `${ message }`
    }

    return translates
  }

  static async updateMessages( groupedTranslates ) {
    universalWebWorker.initialize( { autoTerminate: true } )
    const messages = await universalWebWorker.postMessage( formLocaleMessages, { languages }, JSON.parse( JSON.stringify( groupedTranslates ) ) )

    if ( messages.error ) {
      console.warn( 'При группировки сообщений для i18n произошла ошибка', messages.error )
      return
    }

    for ( const locale in messages.data ) {
      I18nUtils.i18n.global.setLocaleMessage( locale, messages.data[ locale ] )
    }
  }

}
