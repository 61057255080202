import { logger2 } from '@modules/logger2/index'
import { EInstances } from '@static/enums'
import * as consts from './consts'


const writableConsts = { ...consts }

const currentInstance = localStorage.getItem( consts.STORAGE_NAMES.CURRENT_INSTANCE )
if ( import.meta.env.VITE_CURRENT_INSTANCE === EInstances.DEV && currentInstance ) {
  writableConsts.CURRENT_INSTANCE = currentInstance as EInstances
}

export type IGlobals = typeof globals
const globals = {
  ...writableConsts,
  logger2,
}

export const vueGlobalVariables = {
  OPERATION_STATUS: writableConsts.OPERATION_STATUS,
  SUPPORTED_LOCALES: writableConsts.SUPPORTED_LOCALES,
  CURRENT_INSTANCE: writableConsts.CURRENT_INSTANCE,
} as const

const registrateGlobals = () => {
  let global: keyof typeof globals
  for ( global in globals ) {
    const value = globals[ global ]

    if ( !( window as any )[ global ] ) {
      ( window as any )[ global ] = value
    } else {
      if ( import.meta.env.MODE === 'test' ) {
        return
      }
      throw new Error( `window.${ global } уже существует` )
    }
  }
}

registrateGlobals()
