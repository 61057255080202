import {
  type IWorkerPostMessageContainer,
  type IMainThreadPostMessageContainer,
} from './utils'

type IUniversalWorkerPostMessageResult<T> = {
  data?: T;
  error?: Error;
}

type FnArgs<T extends ( ...args: any[] ) => any> = T extends () => any ? [] : Parameters<T> extends [unknown, ...infer Args] ? Args : []
const getMainThreadMessageContainer = <T extends ( ...args: any[] ) => any >( fn: T, helpers: IMainThreadPostMessageContainer<any>['helpers'], ...args: FnArgs<T> ):
IMainThreadPostMessageContainer<FnArgs<T>> => {
  const fnSource = fn.toString()

  return {
    source: fnSource,
    helpers,
    args,
  }
}

interface IUniversalWorkerInitializeParams {
  autoTerminate?: boolean;
}

interface IQueueContainer {
  message: IMainThreadPostMessageContainer<unknown>;
  resolve: ( ( value: IUniversalWorkerPostMessageResult<unknown> ) => void );
}

// eslint-disable-next-line @typescript-eslint/ban-types
type UHelpers = Function | number | string | boolean | object
export const universalWebWorker = new class {
  private _worker: Worker | null = null
  private _resolvePromise: ( ( value: IUniversalWorkerPostMessageResult<unknown> ) => void ) | null = null
  private _queue: IQueueContainer[] = []

  initialize( { autoTerminate }: IUniversalWorkerInitializeParams ) {
    if ( !window.Worker ) {
      console.warn( 'Web Worker API не поддерживается браузером' )
      return OPERATION_STATUS.UNSUPPORTED
    }

    if ( this._worker === null ) {
      this._worker = new Worker( new URL( './universalWorker.ts', import.meta.url ), { type: 'module' } )

      this._worker.onmessage = ( e: MessageEvent<IWorkerPostMessageContainer<unknown>> ) => {
        try {
          if ( this._resolvePromise !== null ) {
            if ( e.data.status === OPERATION_STATUS.ERROR ) {
              console.group( '%cПри выполнении задачи в универсальном воркере произошла ошибка', `color: ${ CONSOLE_COLORS.LOG_ERROR_COLOR }` )
              console.warn( e.data.payload )
              console.groupEnd()

              this._resolvePromise( { error: e.data.payload as Error } )
              return
            }

            this._resolvePromise( { data: e.data.payload } )
            this._resolvePromise = null
          } else {
            console.warn( '_resolvePromise=null; При прослушивании сообщения от воркера не было установлено значение разрешения[resolve] промиса;' )
          }
        } finally {
          const queueContainer = this._queue.shift()

          if ( queueContainer ) {
            this._doPosting( queueContainer )
          } else if ( autoTerminate ) {
            this._worker!.terminate()
            this._worker = null
          }

        }
      }
    }
  }

  async postMessage<T extends ( ...args: any[] ) => any, R = Awaited<ReturnType<T>> >( fn: T, helpers: Record<string, UHelpers>, ...args: FnArgs<T> ): Promise<IUniversalWorkerPostMessageResult<R>> {

    const preparedHelpers = this._prepareHelpers( helpers )
    const message = getMainThreadMessageContainer( fn, preparedHelpers, ...args )

    let resolvePromise = null as ( ( value: IUniversalWorkerPostMessageResult<unknown> ) => void ) | null
    const promise = new Promise<IUniversalWorkerPostMessageResult<unknown>>( ( resolve ) => resolvePromise = resolve )

    if ( this._worker === null ) {
      console.warn( 'Универсальный воркер был неявно инициализирован!' )
      const initializationResult = this.initialize( { autoTerminate: true } )

      if ( initializationResult === OPERATION_STATUS.UNSUPPORTED ) {
        return { error: new Error( 'Web Worker API не поддерживается браузером' ) }
      }
    }

    if ( this._resolvePromise === null ) {
      this._doPosting( {
        resolve: resolvePromise!,
        message,
      } )
    } else {
      this._queue.push( {
        message,
        resolve: resolvePromise!,
      } )
    }

    const result = ( await promise ) as IUniversalWorkerPostMessageResult<R>
    return result
  }

  _doPosting( queueContainer: IQueueContainer ) {
    this._resolvePromise = queueContainer.resolve
    this._worker!.postMessage( queueContainer.message )
  }

  private _prepareHelpers( helpers: Record<string, UHelpers> ) {
    const preparedHelpers: IMainThreadPostMessageContainer<any>['helpers'] = {}
    for ( const helperName in helpers ) {
      const value = typeof helpers[ helperName ] === 'function' ? helpers[ helperName ].toString()
        : helpers[ helperName ]

      preparedHelpers[ helperName ] = {
        value,
        type: typeof helpers[ helperName ] === 'function' ? 'function' : 'source_value',
      }
    }

    return preparedHelpers
  }

}
