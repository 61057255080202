export const convertToPascalCase = ( word: string ): string => {
  const words = word.toLowerCase().split( '_' )
  const convertedWords = words.map( w => w.charAt( 0 ).toUpperCase() + w.slice( 1 ) )
  return convertedWords.join( '' )
}

export const toConstantCase = ( input: string ) => {
  const words = input.split( /(?=[A-Z])/ )
  const output = words.map( word => word.toUpperCase() ).join( '_' )
  return output
}

export enum EDictTypes {
  StringType = 1,
  NumberType
}
export type IEnumWrapper = { enum: object; type: EDictTypes }
export type IDictArray<EnumDict extends Record<string, IEnumWrapper>, T extends EnumDict[keyof EnumDict]['enum']> = Array<{
  [key in keyof T]: key extends string ? { name: string; id: T[key]} : never
}[keyof T]>
