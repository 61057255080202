/* eslint-disable no-console */
import { createResult } from '@/store/utils'
import { type ApiResponse } from '@qdrant/openapi-typescript-fetch'

import * as Sentry from '@sentry/vue'
import { type IOEntity, type IORes } from '@api'

type UHttpMethods =
| 'GET'
| 'POST'
| 'PUT'
| 'DELETE'
| 'PATCH'
| 'HEAD'
| 'OPTIONS'
| 'CONNECT'
| 'TRACE'

export class FetchError extends Error {
  method: UHttpMethods
  status: number

  name = 'FetchError'

  constructor( status: number, method: UHttpMethods, ...args: ConstructorParameters<typeof Error> ) {
    super( ...args )

    this.status = status
    this.method = method
  }
}

export const logger2 = new class Logger2 {
  unexceptedResponseStatus( response: ApiResponse, method: null | UHttpMethods = null ) {
    const resHeaders: Record<string, string|null> = {}
    response.headers.forEach( ( value, header ) => {
      resHeaders[ header ] = value
    } )

    Sentry.addBreadcrumb( {
      category: 'unexcepted-fetch',
      type: 'http',
      level: 'info',
      data: {
        'url': response.url,
        method,
        'status_code': response.status,

        'reason': response.statusText,
        resDataTypeof: response.data ? typeof response.data : null,
        resHeaders,
      },
    } )
    const error = new Error( 'Запрос прошел успешно, но сервер вернул неожиданный статус ответа, который фронт не умеет обрабатывать' )
    error.name = 'Unexpected Fetch Result'
    Sentry.captureException( error )
  }

  captureException( errorMsg: string, scopeContext?: Parameters<typeof Sentry['captureException']>[1] ) {
    const error = new Error( errorMsg )
    error.name = 'Internal Thrown Error'

    const scopeContextCastedToAny = scopeContext as any
    if ( scopeContextCastedToAny && scopeContextCastedToAny?.extra ) {
      scopeContextCastedToAny.extra = { payload: JSON.stringify( scopeContextCastedToAny.extra, null, 2 )  }
    }

    Sentry.captureException( error, scopeContext )
  }

  checkStoreError( err: unknown, ...blacklist: number[] ) {
  }

  internalError( err: Error ) {

    // {
    //   console.groupCollapsed( '%cВнутрення ошибка в работе фронта:', `color: ${ CONSOLE_COLORS.LOG_ERROR_COLOR }; font-weight: bold; text-decoration: underline;` )


    //   console.log( '%cСообщение:', `color: ${ CONSOLE_COLORS.LOG_ACCENT_COLOR }; font-weight: bold;`, err.message )

    //   {
    //     console.group( '%cОбъект ошибки', `color: ${ CONSOLE_COLORS.LOG_ACCENT_COLOR }; font-weight: bold;` )
    //     console.log( err )

    //     {
    //       console.group( '%cStacl:', `color: ${ CONSOLE_COLORS.LOG_ACCENT_COLOR }; font-weight: bold;` )
    //       console.log( err.stack )
    //       console.groupEnd()
    //     }

    //     console.groupEnd()
    //   }

    //   console.groupEnd()
    // }

    return createResult( { operationStatus: OPERATION_STATUS.ERROR, data: 'Внутреняя ошибка работы' } )
  }

}

export type ILogger2 = typeof logger2
