import { type I18nT } from '@/types/utils'
import { type IDictArray } from './_utils'

import { EDictTypes } from './_utils'
import { type IEnumContainer, NumberEnumContainer, StringEnumContainer } from './containers'
import { EInstances } from '@static/enums'

import {
  EClientPhoneType,
  ECommunicationStatusFilter,
  ECommunicationStatuses,
  ECommunicationTypes,
  EDayOfWeek,
  EFilteringMethodForStatus,
  EGroupSetting,
  ELanguages,
  ELanguages2,
  EProjectIdentifier,
  EQueueClientStatuses,
  EQueueCommunicationStatusParameterNames,
  EQueueLifeCycleUpdateTypes,
  EQueueLifeCycles,
  EQueueLoanStatuses,
  EQueueSigns,
  EQueueStatuses,
  EQueueTypes,
  EQueueUpdateTypes,
  EQueuesCommunicationStatuses,
  EServiceResult,
  ESubjectOfCommunicationStatuses,
  ETranslateTypes,
  EUsageZone,
  ETagTypes,
} from './enums'
export {
  EClientPhoneType,
  ECommunicationStatusFilter,
  ECommunicationStatuses,
  ECommunicationTypes,
  EDayOfWeek,
  EFilteringMethodForStatus,
  EGroupSetting,
  ELanguages,
  ELanguages2,
  EProjectIdentifier,
  EQueueClientStatuses,
  EQueueCommunicationStatusParameterNames,
  EQueueLifeCycleUpdateTypes,
  EQueueLifeCycles,
  EQueueLoanStatuses,
  EQueueSigns,
  EQueueStatuses,
  EQueueTypes,
  EQueueUpdateTypes,
  EQueuesCommunicationStatuses,
  EServiceResult,
  ESubjectOfCommunicationStatuses,
  ETranslateTypes,
  EUsageZone,
  ETagTypes,
}
export const dicts = {
  QueuesCommunicationStatuses: {
    enum: EQueuesCommunicationStatuses,
    type: EDictTypes.NumberType,
  },
  QueueSigns: {
    enum: EQueueSigns,
    type: EDictTypes.NumberType,
  },
  QueueTypes: {
    enum: EQueueTypes,
    type: EDictTypes.NumberType,
  },
  QueueLifeCycles: {
    enum: EQueueLifeCycles,
    type: EDictTypes.NumberType,
  },
  QueueStatuses: {
    enum: EQueueStatuses,
    type: EDictTypes.NumberType,
  },
  QueueClientStatuses: {
    enum: EQueueClientStatuses,
    type: EDictTypes.NumberType,
  },
  QueueLoanStatuses: {
    enum: EQueueLoanStatuses,
    type: EDictTypes.NumberType,
  },
  CommunicationTypes: {
    enum: ECommunicationTypes,
    type: EDictTypes.NumberType,
  },
  TranslateTypes: {
    enum: ETranslateTypes,
    type: EDictTypes.NumberType,
  },
  QueueUpdateTypes: {
    enum: EQueueUpdateTypes,
    type: EDictTypes.NumberType,
  },
  UsageZone: {
    enum: EUsageZone,
    type: EDictTypes.NumberType,
  },
  QueueLifeCycleUpdateTypes: {
    enum: EQueueLifeCycleUpdateTypes,
    type: EDictTypes.NumberType,
  },
  CommunicationStatuses: {
    enum: ECommunicationStatuses,
    type: EDictTypes.NumberType,
  },
  ProjectIdentifier: {
    enum: EProjectIdentifier,
    type: EDictTypes.NumberType,
  },
  Languages: {
    enum: ELanguages,
    type: EDictTypes.StringType,
  },
  Languages2: {
    enum: ELanguages2,
    type: EDictTypes.NumberType,
  },
  SubjectOfCommunicationStatuses: {
    enum: ESubjectOfCommunicationStatuses,
    type: EDictTypes.NumberType,
  },
  ServiceResult: {
    enum: EServiceResult,
    type: EDictTypes.StringType,
  },
  DayOfWeek: {
    enum: EDayOfWeek,
    type: EDictTypes.NumberType,
  },
  CommunicationStatusFilter: {
    enum: ECommunicationStatusFilter,
    type: EDictTypes.NumberType,
  },
  FilteringMethodForStatus: {
    enum: EFilteringMethodForStatus,
    type: EDictTypes.NumberType,
  },
  QueueCommunicationStatusParameterNames: {
    enum: EQueueCommunicationStatusParameterNames,
    type: EDictTypes.NumberType,
  },
  ClientPhoneType: {
    enum: EClientPhoneType,
    type: EDictTypes.NumberType,
  },
  GroupSetting: {
    enum: EGroupSetting,
    type: EDictTypes.NumberType,
  },
} as const
export type IDicts = typeof dicts


const containers = ( Object.keys( dicts ) as ( keyof typeof dicts )[] ).reduce( ( acc, dict ) => {
  const propValue = dicts[ dict ]

  if ( propValue.type === EDictTypes.StringType ) {
    ( acc as any )[ dict ] = new StringEnumContainer( propValue.enum )
  } else {
    ( acc as any )[ dict ] = new NumberEnumContainer( propValue.enum )
  }

  return acc
}, {} ) as {[key in keyof typeof dicts]: IEnumContainer<typeof dicts, typeof dicts[key]['enum']>}

export const getDictArray = <Dict extends keyof typeof dicts> ( dictName: Dict, t?: I18nT, blackLists?: Record<EInstances, typeof dicts[Dict]['enum'][keyof typeof dicts[Dict]['enum']][]> ): IDictArray<typeof dicts, typeof dicts[Dict]['enum']> => {
  const container = containers[ dictName ]
  const dictArray = container.getDictArray( t )

  let blackList = [] as NonNullable<typeof blackLists>[EInstances]

  if ( blackLists ) {
    if ( !blackLists[ CURRENT_INSTANCE ] ) {
      logger2.captureException( `Отсутствует массив(черный список значения словаря "${ dictName }") для инстанса "${ CURRENT_INSTANCE }" `, {
        extra: {
          instances: EInstances,
          blackLists: blackLists,
        },
      } )

      return dictArray
    }

    blackList = blackLists[ CURRENT_INSTANCE ]
  }

  return dictArray.filter( item => !blackList.includes( item.id as any ) )
}

export const getDictValueTranslate = <Dict extends keyof typeof dicts>( dictName: Dict, value?: typeof dicts[Dict]['enum'][keyof typeof dicts[Dict]['enum']] | null, t?: I18nT ): string | null => {
  try {
    if ( !value ) {
      return ''
    }

    const container = containers[ dictName ]
    return container.getDictValueTranslate( value as any, t )
  } catch ( err ) {
    console.group( `При попытке получить значение "${ value }" у словаря "${ dictName }" произошла ошибка` )
    console.warn( 'СЛОВАРЬ:\n', JSON.stringify( containers[ dictName ].getDictArray(), null, 2 ) )
    console.warn( ( err as Error ).message )
    console.groupEnd()
    return null
  }
}
