
export enum EInternalSubsystems {
  ADMIN_PANEL = 'AdminPanel',
  WORK_PLACE = 'WorkPlace',
}

export enum EExternalSubsystems {
  REPORTS = 'ReportsWorkplace',
  KNOWLEDGE_BASE = 'KnowledgeBase'
}

export type USubsystems = EInternalSubsystems | EExternalSubsystems


export enum EWidgetCommunicationType {
  CALL = 1,
  SMS = 2,
  EMAIL = 3,
  WHATSAPP = 5,
  WEB = 6,
  PUSH = 9
}

export enum EInstances {
  MX = 'Mexico',
  DEV = 'Development',
  CO = 'Colombia',
  IN = 'India',
  PE = 'Peru',
  UZ = 'Uzbekistan',
  PK = 'Pakistan',
  LMS = 'DevelopmentLMS'
}

/**
 * Частичный список ролей
 */
export enum ERoles {
  ADMIN = 'Admin',
  TESTING_ADMIN = 'TestingAdmin'
}

export enum EFileExtension {
  JPEG = 'jpeg',
  JPG = 'jpg',
  GIF = 'gif',
  PNG ='png',
  DOC= 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX ='xlsx',
  PDF = 'pdf',
  TXT = 'txt',
  MP3 = 'mp3'
}

