import { type I18nT } from '@/types/utils'
import { convertToPascalCase, type IDictArray, type IEnumWrapper } from './_utils'


export interface IEnumContainer<EnumDict extends Record<string, IEnumWrapper>, T extends EnumDict[keyof EnumDict]['enum']> {
  getDictArray( t?: I18nT ): IDictArray<EnumDict, T>;
  getDictValueTranslate( value: T[keyof T] | null, t?: I18nT ): string | null;
}

export class NumberEnumContainer<EnumDict extends Record<string, IEnumWrapper>, T extends EnumDict[keyof EnumDict]['enum']> implements IEnumContainer<EnumDict, T> {
  protected _enum: T

  constructor( __enum: T ) {
    this._enum = __enum
  }

  getDictArray( t?: I18nT ): IDictArray<EnumDict, T> {
    const dictItemNames = Object.keys( this._enum as object ).filter( key => isNaN( parseInt( key ) ) )

    const dictItems = dictItemNames.map( ( itemName ) => ( {
      name: convertToPascalCase( itemName ),
      id: this._enum[ itemName as keyof T ],
    } ) ) as unknown as IDictArray<EnumDict, T>

    return t ? dictItems.map( item => ( {
      ...item,
      name: t( `Common.${ item.name }` ),
    } ) ) : dictItems
  }

  getDictValueTranslate ( value: T[keyof T] | null, t?: I18nT ): string | null {
    if ( value === null ) {
      return null
    }

    const translateName = convertToPascalCase( ( this._enum as any )[ value ] )
    return t ? t( 'Common.' + translateName ) : translateName
  }
}

export class StringEnumContainer<EnumDict extends Record<string, IEnumWrapper>, T extends EnumDict[keyof EnumDict]['enum'], EnumValues extends T[keyof T] = T[keyof T]> implements IEnumContainer<EnumDict, T> {
  private _enum: T
  private _enumValues: EnumValues[]

  constructor( __enum: T ) {
    this._enum = __enum
    this._enumValues = Object.keys( __enum ).map( key => __enum[ ( key as keyof T ) ] ) as EnumValues[]
  }

  getDictArray( t?: I18nT ): IDictArray<EnumDict, T> {
    const enumKeys = Object.keys( this._enum )
      .filter( ( prop: any ) => !this._enumValues.includes( prop ) ) as Array<keyof T>
    const languageDictArray = enumKeys.map( ( key ) => ( {
      id: this._enum[ key ],
      name: ( key as string )[ 0 ] + ( key as string ).slice( 1 ).toLowerCase(),
    } ) )

    const langDict = t ? languageDictArray.map( item => ( {
      ...item,
      name: t( `Common.${ item.name }` ),
    } ) ) : languageDictArray

    return langDict as IDictArray<EnumDict, T>
  }

  getDictValueTranslate ( value: EnumValues | null, t?: I18nT ): string | null {
    if ( value === null ) {
      return null
    }

    const enumProp = Object.keys( this._enum ).find( prop => this._enum[ prop as keyof typeof this._enum ] === value ) as keyof typeof this._enum
    const translateName = convertToPascalCase( enumProp as string )

    return t ? t( 'Common.' + translateName ) : translateName
  }
}
